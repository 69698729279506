import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PermissionsModule } from '@core/permissions/permissions.module';

import { NullComponent } from './components/null/null.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RestrictedAccessComponent } from './components/restricted-access/restricted-access.component';

@NgModule({
  declarations: [NullComponent, PageNotFoundComponent, RestrictedAccessComponent],
  exports: [NullComponent, PageNotFoundComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatExpansionModule,
    PermissionsModule,
    MatButtonModule,
    MatIconModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
  // should be only one import in AppModule
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}

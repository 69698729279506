import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth/repository';
import { NotifyComponent } from '@core/components/notify/notify.component';
import { getBrowser } from '@helpers/browser';

@Component({
  selector: 'ume-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, NotifyComponent],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService) {
    document.body.classList.add(getBrowser());
  }

  ngOnInit(): void {
    this.authService.initApp();
  }
}

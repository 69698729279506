export type Browser = 'ie9' | 'ie10' | 'ie11' | 'ie' | 'edge' | 'chrome' | 'safari' | 'firefox';

export function getBrowser(ua: string = window.navigator.userAgent): string {
  const browser = 'unknown';

  // IE < 11
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return 'ie' + Number.parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  // IE 11
  if (ua.indexOf('Trident/') > 0) {
    const rv = ua.indexOf('rv:');
    return 'ie' + Number.parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // Edge
  if (ua.indexOf('Edge/') > 0) {
    return 'edge';
  }

  // Chrome
  if (ua.indexOf('Chrome/') > 0) {
    return 'chrome';
  }

  // Safari
  if (ua.indexOf('Safari/') > 0) {
    return 'safari';
  }

  // Firefox
  if (ua.indexOf('Firefox/') > 0) {
    return 'firefox';
  }

  if (browser === 'unknown') {
    throw new Error('Browser detection failed for: ' + ua);
  }

  return browser;
}

export function isBrowser(
  browsers: Browser | Browser[],
  ua: string = window.navigator.userAgent
): boolean {
  const browsersStr = Array.isArray(browsers)
    ? /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      browsers.map((x: any) => x.toString())
    : [browsers.toString()];
  const browser = getBrowser(ua);

  if (browsersStr.indexOf('ie') > -1 && browser.startsWith('ie')) {
    return true;
  } else {
    return browsersStr.indexOf(browser) > -1;
  }
}

// IE Object.entries polyfill
export function getEntries(obj: { [s: string]: unknown } | ArrayLike<any>): any[] {
  // @ts-ignore
  return Object.entries ? Object.entries(obj) : Object.keys(obj).map((key) => [key, obj[key]]);
}

import {
  HttpEvent,
  type HttpHandler,
  HttpHeaders,
  type HttpInterceptor,
  type HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getSessionUser } from '@auth/repository/auth.repository';
import { environment } from '@env/environment';
import { cookies } from '@shared/domain/ume-urls';
import { getCookieByName } from '@shared/helpers/helpers';
import { Observable } from 'rxjs';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clone: HttpRequest<any>;

    if (request.url.startsWith('/')) {
      const companyId: string =
        request.headers.get('X-ume-CompanyId') ||
        getSessionUser().selectedCompany?.guid.toString() ||
        '';
      const xsrfCookie = getCookieByName(cookies.COOKIE_NAME());
      let headers: HttpHeaders = new HttpHeaders();

      request.headers.keys().forEach((key) => {
        const value = request.headers.get(key);
        if (value !== null) {
          headers = headers.set(key, value);
        }
      });

      headers = headers.set('X-ume-CompanyId', companyId);

      if (xsrfCookie) {
        headers = headers.set(cookies.HEADER_NAME(), xsrfCookie);
      } else {
        // console.log( 'Cookie not found: ', COOKIE_NAME(), document.cookie );
      }

      clone = request.clone({
        url: environment.API + request.url,
        withCredentials: true,
        headers,
      });
    } else {
      clone = request;
    }

    return next.handle(clone);
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  type HttpHandler,
  type HttpInterceptor,
  type HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth/repository';
import { isLoggedInUser } from '@auth/repository/auth.repository';
import { NotifyService } from '@core/services/notifyService.service';
import { environment } from '@env/environment';
import { DIALOG_SIZE } from '@shared/constants/ume-constants';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private notifyService: NotifyService,
    public dialog: MatDialog
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((_) => {
        // to enforce session update or end it
        if (request.url.indexOf('/auth/logout') < 0) {
          this.authService.testRequest();
        }
      }),
      catchError((err: HttpEvent<any>) => {
        if (err instanceof HttpErrorResponse) {
          console.log('***Server error!***', request.url, err);
          switch (err.status) {
            case 0:
              this.notifyService.notifyErrorOnly(
                'Your organisation is blocking access to ume. Please contact your IT support.'
              );
              break;
            case 401:
              // clear test fetching timer
              this.authService.testRequest(true);

              // log out only for internal restriction
              const isUmeRequest = request.url.indexOf(environment.API) !== -1;
              const isAlreadyOpen = Boolean(this.dialog.openDialogs.length);
              if (
                isUmeRequest &&
                !isAlreadyOpen &&
                isLoggedInUser() &&
                !request.url.endsWith('/auth/login')
              ) {
                const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                  data: {
                    title: 'Session expired',
                    text: `
                        <p class="text">Your session has expired.</p>
                        <p class="text">Please log in again.</p>
                      `,
                    approveLabel: 'Sign in',
                    submitOnly: true,
                  },
                  autoFocus: true,
                  width: DIALOG_SIZE.MODAL_SM,
                });

                dialogRef
                  .afterClosed()
                  .pipe()
                  .subscribe((_b: boolean) => {
                    this.authService.logout();
                  });
              }
              break;
            case 403:
              this.notifyService.notifyWarn(
                `Request id: ${err.status}` +
                  '\n' +
                  `${err.error.auditTrailId ?? err.error.stackTrace}`
              );
              break;
            case 404:
              this.notifyService.notifyErrorOnly('Not implemented yet');
              break;
            case 400:
              this.notifyService.notifyErrorOnly(err.error.message);
              break;
            case 500:
              const message = err.error.message || 'Something went wrong';
              this.notifyService.notifyErrorOnly(message);
              break;
            default:
              this.notifyService.notifyWarn(
                `Request id: ${err.status}` +
                  '\n' +
                  `${err.error.auditTrailId ?? err.error.stackTrace}`
              );
              break;
          }
        }
        return throwError(err);
      })
    );
  }
}
